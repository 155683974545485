@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 rounded-full focus:ring-indigo-500 
    focus:border-indigo-500 block w-full sm:text-sm text-neutral-900 dark:text-neutral-200;
  }
  ::-webkit-scrollbar {
    @apply h-1 w-1 bg-white;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-black rounded-full;
  }

  ::-webkit-scrollbar-corner {
    @apply bg-black;
  }

  h1 {
    @apply text-4xl font-bold;
  }

  h2 {
    @apply text-3xl font-semibold;
  }

  h3 {
    @apply text-2xl font-semibold;
  }

  blockquote {
    @apply italic border-l-4 border-blue-500 pl-4 text-gray-700 dark:text-gray-300;
  }

  figure {
    @apply my-6 flex flex-col items-center;
  }

  figcaption {
    @apply text-center mt-2 text-sm text-gray-600;
  }

  hr {
    @apply border-t-2 border-gray-300 dark:border-gray-600 my-6;
  }

  br {
    @apply my-4; /* Adding margin around line breaks */
  }
}

@layer utilities {
  .clip-path-custom {
    clip-path: path(
      "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
    );
  }
}

@font-face {
  font-family: "baskerville-old-face";
  src: url("../public/baskerville-old-face.woff2") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

.brandname {
  height: 19vh;
  transform: translateY(100%);
}

.__col {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

/* Start Animations */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 20s linear infinite;
}
@keyframes wigggle {
  0% {
    transform: "rotate(-3deg)";
  }
  50% {
    transform: "rotate(3deg)";
  }
  100% {
    transform: "rotate(-3deg)";
  }
}

.animate-gradient {
  background: linear-gradient(
    300deg,
    #7c3aed,
    /* violet-600 */ #d946ef,
    /* fuchsia-500 */ #ec4899,
    /* pink-500 */ #7c3aed /* back to violet-600 for seamless loop */
  );
  background-size: 300% 300%;
  animation: gradient-animation 8s ease-in-out infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Optional: Add a shimmering effect */
.animate-gradient-shimmer {
  position: relative;
  overflow: hidden;
}

@keyframes bounce-gentle {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}
.animate-bounce-gentle {
  animation: bounce-gentle 2s infinite;
}

.animate-gradient-shimmer::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: linear-gradient(
    45deg,
    transparent 20%,
    rgba(255, 255, 255, 0.1) 25%,
    transparent 30%
  );
  animation: shimmer 4s ease-in-out infinite;
  transform: rotate(30deg);
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) rotate(30deg);
  }
}

/* Variant with more playful movement */
.animate-gradient-party {
  background: linear-gradient(
    45deg,
    #7c3aed 0%,
    #d946ef 25%,
    #ec4899 50%,
    #d946ef 75%,
    #7c3aed 100%
  );
  background-size: 400% 400%;
  animation: gradient-party 8s ease infinite;
}

@keyframes gradient-party {
  0%,
  100% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0% 100%;
  }
}

/* Pulsing variant */
.animate-gradient-pulse {
  background: linear-gradient(135deg, #7c3aed 0%, #d946ef 50%, #ec4899 100%);
  background-size: 200% 200%;
  animation: gradient-pulse 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes gradient-pulse {
  0% {
    background-position: 0% 0%;
    transform: scale(1);
  }
  50% {
    background-position: 100% 100%;
    transform: scale(1.02);
  }
  100% {
    background-position: 0% 0%;
    transform: scale(1);
  }
}

/* Radial variant */
.animate-gradient-radial {
  background: radial-gradient(circle at center, #7c3aed, #d946ef, #ec4899);
  background-size: 200% 200%;
  animation: gradient-radial 8s ease infinite;
}

@keyframes gradient-radial {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 200% 200%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}
.animate-blob {
  animation: blob 7s infinite;
}
.animation-delay-2000 {
  animation-delay: 2s;
}
.animation-delay-4000 {
  animation-delay: 4s;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  background: rgba(48, 4, 243, 0.2);
  animation: animate 25s linear infinite;
  border-radius: 50%;
}

.circles li:nth-child(1) {
  width: 8vw;
  height: 8vw;
  left: 25%;
  top: 10%;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  width: 2vw;
  height: 2vw;
  left: 10%;
  top: 50%;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  width: 3vw;
  height: 3vw;
  left: 70%;
  top: 30%;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  width: 6vw;
  height: 6vw;
  left: 40%;
  top: 70%;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  width: 4vw;
  height: 4vw;
  left: 65%;
  top: 90%;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  width: 11vw;
  height: 11vw;
  left: 75%;
  top: 50%;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  width: 15vw;
  height: 15vw;
  left: 35%;
  top: 20%;
  animation-delay: 3s;
}

.circles li:nth-child(8) {
  width: 2.5vw;
  height: 2.5vw;
  left: 50%;
  top: 80%;
  animation-delay: 2s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  width: 1.5vw;
  height: 1.5vw;
  left: 20%;
  top: 60%;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  width: 10vw;
  height: 10vw;
  left: 85%;
  top: 15%;
  animation-delay: 0s;
  animation-duration: 11s;
}

.circles li:nth-child(11) {
  width: 5vw;
  height: 5vw;
  left: 15%;
  top: 25%;
  animation-delay: 3s;
  animation-duration: 15s;
}

.circles li:nth-child(12) {
  width: 9vw;
  height: 9vw;
  left: 45%;
  top: 5%;
  animation-delay: 0s;
  animation-duration: 25s;
}

.circles li:nth-child(13) {
  width: 4vw;
  height: 4vw;
  left: 55%;
  top: 55%;
  animation-delay: 2s;
  animation-duration: 20s;
}

.circles li:nth-child(14) {
  width: 7vw;
  height: 7vw;
  left: 30%;
  top: 85%;
  animation-delay: 2s;
  animation-duration: 30s;
}

.circles li:nth-child(15) {
  width: 2vw;
  height: 2vw;
  left: 80%;
  top: 40%;
  animation-delay: 5s;
  animation-duration: 15s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
